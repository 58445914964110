<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3">Ajout d'une nouvelle categorie</h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <button
              type="submit"
              class="btn btn-primary"
              @click="showMsgBox()"
              :disabled="nom.length == 0"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Information categorie</h5>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <H5 class="form-label">Nom </H5>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom categorie"
                  v-model="nom"
                />
                <label
                  >Le nom est la façon dont il apparaît sur votre site.</label
                >
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <H5 class="form-label">Slug </H5>
                  </div>

                  <input
                    class="form-control"
                    placeholder="SLUG"
                    v-model="slug"
                  />

                  <label
                    >Le « slug » est la version du nom normalisée pour les
                    URL.</label
                  >
                </div>
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <h5 class="form-label">Catégorie Parent</h5>

                    <feather-icon
                      style="color: #19b4bf; cursor: pointer"
                      icon="RefreshCcwIcon"
                      size="20"
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      v-b-tooltip.hover.v-dark
                      title="Refresh category list"
                      variant="gradient-primary"
                      @click="refrechCategoryParent()"
                    />
                  </div>
                  <v-select
                    style="cursor: pointer"
                    :loading="categoriesLoader"
                    v-model="categorySelected"
                    :close-on-select="false"
                    :options="categoryParent.categories"
                    label="name"
                    input-id="id"
                    value="id"
                  />
                  <label
                    >Assignez un terme parent pour créer une hiérarchie.</label
                  >
                </div>
              </div>

              <!-- Description -->
              <div>
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="description" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Media</h5>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <b-overlay
                  :show="imageOverlay"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                >
                  <vue-upload-multiple-image
                    v-model="imagesUrl"
                    :data-images="imagesUrl"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :max-image="1"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageAndReturnSrc"
                    @before-remove="beforeRemove"
                    :showEdit="false"
                  >
                  </vue-upload-multiple-image>
                </b-overlay>
                <div class="col">
                  <div class="mb-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { fetchCategorie } from "../../views/Categories/CategoryFunction.js";
import { uploadImageSuccess } from "../../views/Products/ProductSettings/FileUpload.js";
import axios from "axios";
import websiteURL from "@/websiteURL";
import { BOverlay, VBTooltip } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    quillEditor,
    VueUploadMultipleImage,
    BOverlay,
    VBTooltip,
    vSelect,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      imagesUrl: [],
      imageOverlay: false,
      categoryParent: [],
      nom: "",
      slug: "",
      description: "",
      categorySelected: 0,
      category: { image: [] },
      image: { src: "" },
      boxMsg: "",
      loading: false,
      categoriesLoader: false,
    };
  },
  methods: {
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageOverlay = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );

        // Push the uploaded image URLs into the 'images' array
        this.category.image.push(...uploadedUrls);

        this.imageOverlay = false;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },

    async addCategory() {
      if (this.category.image[0]) {
        this.image.src = this.category.image[0].src;
      }
      const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/categories";
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          name: this.nom,
          description: this.description,
          slug: this.slug,
          parent: this.categorySelected.id,
          image: this.image,
        };
        const response = await axios.post(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.makeToastMsg("info");
      } catch (error) {
        alert("Erreur lors de l'ajout de la catégorie:", error);
      }
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
      }
    },

    showMsgBox() {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir ajouter la catégorie ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.addCategory().then(() => {
                this.loading = false;
              });
            }
          }
        });
    },
    makeToastMsg(variant = null) {
      this.$bvToast.toast("La catégorie a bien été ajouter", {
        title: `Catégorie ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    async refrechCategoryParent() {
      this.categoriesLoader = true;
      this.categoryParent = await fetchCategorie();
      this.categoriesLoader = false;
    },
  },
  async created() {
    this.categoryParent = await fetchCategorie();
  },
};
</script>

<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 150px;
    }
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
